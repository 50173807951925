<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div class="sabc_video_player" v-else-if="is_data_ready" style="background: #041c50;">
        <!-- sabc tv show slider start -->
        <section class="sabc_video_player">
            <div class="container">
                <video class="sabc_big_vido" width="400" height="350" controls controlsList="nodownload" oncontextmenu="return false;">
                    <source :src="base_url+video.cast_url" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
                <div class="sabc_video_player_dec">
                    <h1>{{video.title}}</h1>
                    <p></p>
                    <ul>
                        <li>
                            <span>
                                <img src="/public/images/sabc_playervideos_timer.png" alt="sabc_showvideos_timer"/>
                                {{new Date(video.duration*1000).toISOString().slice(11, 19)}}
                            </span>
                        </li>
                        <li>
                          <i>|</i>
                        </li>
                        <li>
                          <span>
                            {{video.release_date}}
                          </span>
                        </li>
                    </ul>
                    <router-link class="sabc_video_player_back" :to="{ name: 'video_category', params: { category: category.slug}}"> &lt; Back to {{video.category}}</router-link>
                </div>
            </div>
        </section>
        <!-- sabc tv show slider end  -->
        <!-- video area start -->
        <section class="container">
            <div class="sabc_videos_show_latest">
                <h2>Latest Episodes</h2>
                <div class="sabc_videos_show_latest_row" v-for="(video, index) in video_list.slice(0,3)" v-if="video.image != null">
                    <router-link class="sabc_show_latest_video_outer" :to="{ name: 'video_player', params: { category: category.slug, player: video.slug}}">
                        <div class="sabc_show_latest_video">
                            <img class="play_icon" src="/assets/img/sabc_video_list_play_icon.png" alt="SABC Play Icon"/>
                            <!-- <img class="video_thumbnail" :src="base_url+video.image" :alt="video.title" loading="lazy"/> -->
                            <source media="(min-width: 575px)" :srcset="imagePath(base_url+video.image)">
                            <img class="video_thumbnail" :src="imagePath(base_url+video.image)" width="444" height="249"  :alt="video.title" loading="lazy">
                        </div>
                    </router-link>
                    <div class="sabc_show_latest_desc">
                        <router-link :to="{ name: 'video_player', params: { category: category.slug, player: video.slug}}"><h3>{{video.title}}</h3></router-link>
                        <p></p>
                        <ul>
                            <li>
                                <p>
                                    <img src="/public/images/sabc_showvideos_timer.png" alt="SABC Show Video Time"/>
                                    {{new Date(video.duration*1000).toISOString().slice(11, 19)}}<i>|</i> {{video.release_date}}
                                </p>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>
        <!-- video area end -->
    </div>
</template>

<script>
    export default {
        name: 'video_player',
        data() {
            return {
                base_url: '',
                video_list: [],
                category: [],
                video: [],
                is_data_ready:false
            }
        },
        components: {},
        mounted() {
            this.getVideoData();
        },
       watch: {
            $route(to, from) {
                this.getVideoData();
            }
        },
        serverPrefetch() {
            this.getVideoData();
        },
        computed: {
        },
        metaInfo() {
           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                let title = this.$getSafe(() => ss.sabc_video_player_listing_meta_title);
                let description = this.$getSafe(() => ss.sabc_video_player_listing_meta_description);
                let keywords = this.$getSafe(() => ss.sabc_video_player_listing_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },

        methods: {
            getVideoData() {
                let params = {category: this.$route.params.category}
                this.is_data_ready = false;
                return this.$axios.get(this.$GetVideoCategoryList, {params})
                        .then(response => {
                            if (response.data) {
                                this.base_url = response.data.base_url;
                                this.video_list = response.data.video_list;
                                this.category = response.data.category;

                                this.video_list.forEach((value) => {
                                    if(value.slug == this.$route.params.player){
                                        this.video = value;
                                    }
                                });
                                this.is_data_ready = true;
                            }
                        })
                        .catch(error => {
                        })
            },
            imagePath(path){
                let lastSplit = path.replace(/\\/g,'/').replace(/.*\//, '').replace(/.jpeg/, '')+'_444x249.webp';
                return this.$storageURL+'images/video/'+lastSplit;
            },
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/videos_player.scss';
</style>
